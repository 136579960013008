@tailwind base;
@tailwind components;
@tailwind utilities;

.font-sf, .body-font {
	font-family: 'SF';
}

.font-zapfino, .script-font {
	font-family: 'Zapfino';
}

.logo-sm {
	height: 65px;
}

.logo {
	height: 75px;
}


.preorder-video-sm {
	width: 300px;
	height: 585px;
}

.preorder-video {
	width: 450px;
}

.social-icon {
	height: 40px;
	width: 40px;
}

.social-icon img {
	height: 24px;
}

.social-icon-mobile {
	height: 60px;
	width: 60px;
}

.social-icon-mobile img {
	height: 30px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}