@font-face {
    font-family: 'SF';
    font-weight: 700;
    src: local('SF'),
         url('./assets/fonts/SF/SF-Compact-Text-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 700;
    font-style: italic;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-BoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 900;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-Heavy.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 900;
    font-style: italic;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-HeavyItalic.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 300;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-Light.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 300;
    font-style: italic;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-LightItalic.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 500;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 500;
    font-style: italic;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-MediumItalic.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 400;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 400;
    font-style: italic;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-RegularItalic.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 600;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-Semibold.otf') format('opentype');
}

@font-face {
    font-family: 'SF';
    font-weight: 600;
    font-style: italic;
    src: local(SF),
         url('./assets/fonts/SF/SF-Compact-Text-SemiboldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Zapfino';
    src: local('Zapfino'),
         url('./assets/fonts/Zap/Zapfino.ttf') format('truetype');
}